<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-6"
      :style="{ textAlign: formData.titleAlign, color: formData.textColor }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="gap-6"
      :class="[
        gridColsClass,
        formData.isUseSliderOnMobile ? 'hidden md:grid' : 'grid',
      ]"
    >
      <template
        v-for="(card, index) in formData.cards"
        :key="'cards-grid' + index"
      >
        <CardsItem
          :card="card"
          :is-bordered="formData.isBordered"
          :border-color="formData.borderColor"
          :is-shadowed="formData.isShadowed"
          :shadow-color="formData.shadowColor"
        />
      </template>
    </div>
    <div :class="formData.isUseSliderOnMobile ? 'block md:hidden' : 'hidden'">
      <Swiper
        :slides-per-view="'auto'"
        :space-between="SLIDES_GAP"
        :pagination="{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 10,
        }"
        :modules="swiperModules"
        class="swiper-custom"
        :class="formData.isShadowed && 'swiper-shadowed'"
      >
        <template
          v-for="(card, index) in formData.cards"
          :key="'cards-slider' + index"
        >
          <SwiperSlide
            :style="{
              padding: '0 4px',
              marginRight: SLIDES_GAP + 'px',
              width: getSlideWidth(formData.colsNumberOnMobile, SLIDES_GAP),
            }"
          >
            <CardsItem
              :card="card"
              :is-bordered="formData.isBordered"
              :border-color="formData.borderColor"
              :is-shadowed="formData.isShadowed"
              :shadow-color="formData.shadowColor"
            />
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Grid, Pagination } from 'swiper/modules'
import CardsItem from './CardsItem.vue'
import { getGridColsClass, getSlideWidth } from '~/components/base/utils'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()
const SLIDES_GAP = computed(() => (props.formData.isShadowed ? 16 : 24))

const swiperModules = [Grid, Pagination]

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})
</script>
