import { basePropertiesSchema } from '../base-schema-properties'
import { bgColorSchema, shadowSchema, textColorSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Event Header',
  description: 'Event header block with cover, event info and action buttons',
  type: 'object',
  properties: {
    ...basePropertiesSchema,
    ...bgColorSchema,
    ...textColorSchema,
    ...shadowSchema,
    hideImage: {
      title: 'Hide image',
      type: 'boolean',
      default: false,
    },
    hideTitle: {
      title: 'Hide title',
      type: 'boolean',
      default: false,
    },
    hideDates: {
      title: 'Hide dates',
      type: 'boolean',
      default: false,
    },
    hideGoogleCalendar: {
      title: 'Hide google calendar',
      type: 'boolean',
      default: false,
    },
    hideHubDates: {
      title: 'Hide online platform (hub) availability dates',
      type: 'boolean',
      default: false,
    },
    hidePlace: {
      title: 'Hide place',
      type: 'boolean',
      default: false,
    },
  },
}
