import {
  create,
  NConfigProvider,
  NInput,
  NDatePicker,
  NSpace,
  NFormItem,
  NInputNumber,
  NForm,
  NButton,
  NButtonGroup,
  NSelect,
  NRadio,
  NRadioGroup,
  NSwitch,
} from 'naive-ui'

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp

  if (process.server) {
    return
  }

  const naive = create({
    components: [
      NButton,
      NForm,
      NInputNumber,
      NFormItem,
      NSpace,
      NDatePicker,
      NInput,
      NConfigProvider,
      NButtonGroup,
      NSelect,
      NRadio,
      NRadioGroup,
      NSwitch,
    ],
  })

  vueApp.use(naive)
})
