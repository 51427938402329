<template>
  <div
    :id="uuid"
    class="rounded-xl min-h-[470px] overflow-hidden flex flex-col"
    :class="[formData.blockShadow && 'shadow-lg']"
  >
    <div
      v-if="!formData.showcaseSlug"
      class="w-full h-full flex-grow p-8 bg-white"
    >
      <div class="font-bold text-2xl mb-2">
        {{ $t('showcase_counters.showcase_counters') }}
      </div>
      <div>
        {{ $t('showcase_counters.select_showcase') }}
      </div>
    </div>
    <div
      v-else-if="!isLoaded"
      class="w-full h-full flex-grow bg-white flex justify-center items-center"
    >
      <BaseSpinner />
    </div>
    <div
      v-else
      class="w-full h-full flex-grow bg-[#7BA0FF] bg-[url('/images/showcase-counters/bg-mobile.jpg')] md:bg-[url('/images/showcase-counters/bg.jpg')] bg-cover bg-top flex flex-wrap justify-between items-center px-8 xl:px-14"
    >
      <div
        class="flex-grow xl:flex-[1_0_min-content] flex justify-center 2xl:pl-6 py-10"
      >
        <div
          class="flex flex-col md:flex-row flex-wrap gap-x-12 md:gap-x-24 gap-y-7 xl:gap-y-24"
        >
          <div class="w-max flex items-end">
            <div class="w-[100px] flex items-end">
              <img
                class="h-[63px]"
                src="/images/showcase-counters/heart.svg"
                :alt="$t('showcase_counters.total_games')"
              />
            </div>
            <div
              class="flex flex-col gap-y-1"
              style="text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)"
            >
              <div class="text-2xl text-white">
                {{ $t('showcase_counters.total_games') }}
              </div>
              <div
                class="h-[63px] flex items-center text-5xl font-bold text-white"
              >
                {{ showcaseCounters?.total_requests }}
              </div>
            </div>
          </div>
          <div class="w-max flex items-end">
            <div class="w-[100px] flex items-end">
              <div class="h-[66px] flex items-center">
                <img
                  class="h-[63px]"
                  src="/images/showcase-counters/heart.svg"
                  :alt="$t('showcase_counters.latest_addition')"
                />
              </div>
            </div>
            <div
              class="flex flex-col gap-y-1"
              style="text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)"
            >
              <div class="text-2xl text-white">
                {{ $t('showcase_counters.latest_addition') }}
              </div>
              <div
                class="h-[57px] mt-[6px] flex items-center text-3xl font-bold text-white whitespace-pre-line"
              >
                {{ latestAddition }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-[1_0_auto] flex flex-col xl:flex-row justify-center items-center"
      >
        <div
          ref="canvasContainerElement"
          class="relative w-[100vw] max-w-[460px]"
        >
          <canvas
            ref="canvasElement"
            aria-label="Platforms chart"
            role="img"
          ></canvas>
        </div>
        <div
          v-if="legendItems"
          class="grid grid-flow-col grid-rows-5 md:grid-rows-3 xl:grid-rows-5 gap-y-5 gap-x-14 xl:gap-x-6 pb-10 xl:pb-0 xl:pl-0"
        >
          <div
            v-for="item in legendItems"
            :key="item.index"
            class="flex items-center gap-x-4"
          >
            <div
              class="w-7 h-7 border-[3px] border-white rounded shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]"
              :style="{ backgroundColor: String(item.fillStyle) }"
            />
            <div class="text-lg text-white">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Chart,
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  type LegendItem,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useShowcases } from '~/stores/showcases'
import { useCurrentLang } from '~/stores/current-lang'
import type { ShowcaseRequestsByPlatformsCounter } from '~/models/showcase-counters'

Chart.register(PieController, ArcElement, Tooltip, Legend)

interface FormDataInterface {
  eventId?: number
  showcaseSlug?: string
  blockShadow?: boolean
}

interface PropsInterface {
  formData: FormDataInterface
  uuid: string
}

const props = defineProps<PropsInterface>()

const showcasesStore = useShowcases()
const { showcaseCounters, showcaseCountersProps, isShowcaseCountersLoading } =
  storeToRefs(showcasesStore)

const { $dayjs } = useNuxtApp()
const currentLang = useCurrentLang()
const { t } = useI18n()

const canvasElement = ref<HTMLCanvasElement | null>(null)
const canvasContainerElement = ref<HTMLElement | null>(null)
const legendItems = ref<LegendItem[] | null>(null)

let chart: Chart | null = null
let canvasContainerWidth: number = 0

const PlatformsColors = {
  1: '#00D655', // VR / AR
  7: '#FF6CF0', // Switch
  8: '#7F00AF', // Playstation
  9: '#EEFF2E', // Xbox
  12: '#9EB5D2', // Android
  13: '#0085FF', // iOS
  14: '#0CD3FF', // Other
  16: '#FF8F00', // PC
  20: '#FF0000', // Web / Cloud
}

const isLoaded = computed(() => {
  return (
    showcaseCounters.value &&
    showcaseCountersProps.value.eventId === props.formData.eventId &&
    showcaseCountersProps.value.slug === props.formData.showcaseSlug &&
    !isShowcaseCountersLoading.value
  )
})

const latestAddition = computed<string>(() =>
  showcaseCounters.value?.last_request_date
    ? $dayjs
        .utc(showcaseCounters.value?.last_request_date)
        .locale('showcase_counters_' + currentLang.lang)
        .fromNow()
    : ''
)

watch(
  () => props.formData.showcaseSlug,
  async () => {
    await fetchShowcaseCounters()
    initChart()
  }
)

onServerPrefetch(async () => {
  await fetchShowcaseCounters()
})

onMounted(async () => {
  await fetchShowcaseCounters()
  initChart()

  window.addEventListener('resize', onResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

async function fetchShowcaseCounters(): Promise<void> {
  try {
    if (props.formData.eventId && props.formData.showcaseSlug) {
      await showcasesStore.fetchShowcaseCounters(
        props.formData.eventId,
        props.formData.showcaseSlug
      )
    }
  } catch (error) {
    useLogError(error)
  }
}

const ChartCustomShadowPlugin = {
  id: 'ChartCustomShadowPlugin',
  beforeDraw: (chart: any) => {
    const ctx: CanvasRenderingContext2D | undefined = chart?.ctx
    const firstPieSector = chart?.getDatasetMeta?.(0)?.data?.[0]

    if (!firstPieSector || !ctx) {
      return
    }

    ctx.save()

    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
    ctx.shadowBlur = 20
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 5

    ctx.beginPath()
    ctx.arc(
      firstPieSector.x,
      firstPieSector.y,
      firstPieSector.outerRadius,
      0,
      2 * Math.PI
    )
    ctx.fillStyle = 'white'
    ctx.fill()

    ctx.restore()
  },
}

function initChart() {
  if (!canvasElement.value || !showcaseCounters.value) {
    return
  }

  const innerLabels = getChartInnerLabels()

  chart?.destroy()

  updateCanvasContainerWidth()

  chart = new Chart(canvasElement.value, {
    type: 'pie',
    data: {
      labels: getChartLabels(),
      datasets: [
        {
          label: t('showcase_counters.count'),
          data: getChartData(),
          backgroundColor: getChartColors(),
          parsing: false,
          animation: false,
          borderAlign: () => (canvasContainerWidth < 420 ? 'center' : 'inner'),
        },
      ],
    },
    plugins: [ChartDataLabels, ChartCustomShadowPlugin],
    options: {
      layout: {
        padding: 55,
      },
      plugins: {
        datalabels: {
          color: '#FFFFFF',
          font: () => {
            return { size: canvasContainerWidth < 430 ? 20 : 26 }
          },
          display: 'auto',
          offset: (context) => {
            return isLabelOutside(innerLabels[context.dataIndex] || '') ? 4 : -4
          },
          formatter: (_, context) => {
            return innerLabels[context.dataIndex] || ''
          },
          anchor: (context) => {
            return isLabelOutside(innerLabels[context.dataIndex] || '')
              ? 'end'
              : 'center'
          },
          align: (context) => {
            return isLabelOutside(innerLabels[context.dataIndex] || '')
              ? 'end'
              : 'end'
          },
          textShadowBlur: (context) => {
            return isLabelOutside(innerLabels[context.dataIndex] || '') ? 20 : 0
          },
          textShadowColor: 'rgba(0, 0, 0, 0.25)',
        },
        legend: {
          display: false,
        },
        tooltip: {
          boxPadding: 5,
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${context.formattedValue} (${
                innerLabels[context.dataIndex]
              })`
            },
          },
        },
      },
    },
  })

  legendItems.value =
    chart?.options.plugins?.legend?.labels?.generateLabels?.(chart) || null
}

function updateCanvasContainerWidth() {
  canvasContainerWidth =
    canvasContainerElement.value?.getBoundingClientRect?.().width || 0
}

const requestsByPlatformsSorted = computed<
  ShowcaseRequestsByPlatformsCounter[]
>(() => {
  return (
    showcaseCounters.value?.requests_by_platforms?.sort(
      (a, b) => b.requests_count - a.requests_count
    ) || []
  )
})

function getChartLabels(): string[] {
  return requestsByPlatformsSorted.value.map((counter) => counter.platform_name)
}

function getChartColors(): string[] {
  return requestsByPlatformsSorted.value.map(
    (counter) =>
      PlatformsColors[counter.platform_id as keyof typeof PlatformsColors] || ''
  )
}

function getChartData(): number[] {
  return requestsByPlatformsSorted.value.map(
    (counter) => counter.requests_count
  )
}

function getChartInnerLabels(): string[] {
  const total = requestsByPlatformsSorted.value.reduce(
    (acc, currentValue) => acc + currentValue.requests_count,
    0
  )

  return requestsByPlatformsSorted.value.map(
    (counter) => Math.round((counter.requests_count / total) * 100) + '%'
  )
}

function isLabelOutside(label: string): boolean {
  return label.length < 3
}

function onResize() {
  updateCanvasContainerWidth()
  chart?.resize?.()
}
</script>
