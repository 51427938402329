<template>
  <img
    :src="resizeSrc"
    :width="width"
    :height="height"
    v-bind="{ ...$attrs }"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    default: undefined,
  },
  height: {
    type: [Number, String],
    default: undefined,
  },
})

const resizeSrc = computed(() => {
  return useProcessImage({
    url: props.src,
    width: parseInt(String(props.width)),
    height: parseInt(String(props.height)),
  })
})
</script>
