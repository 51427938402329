<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      color: formData.textColor,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-5 md:mb-6"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <BaseGallery
      :cols-number-on-desktop="formData.colsNumberOnDesktop"
      :cols-number-on-mobile="formData.colsNumberOnMobile"
      :is-use-slider-on-mobile="formData.isUseSliderOnMobile"
      :is-open-fullscreen="formData.isOpenFullscreen"
      :images="formData.images"
    />
  </div>
</template>

<script setup lang="ts">
import BaseGallery from '~/components/base/BaseGallery.vue'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

defineProps<PropsInterface>()
</script>
