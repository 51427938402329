import { createPixel, debug } from './helpers'

export default defineNuxtPlugin(() => {
  const id = useRuntimeConfig().public.facebookPixelId

  function initPixel() {
    try {
      return createPixel(id, !id)
    } catch (error: any) {
      console.warn('error on create FB Pixel script', error)
      return debug
    }
  }

  const fbq = initPixel()

  return {
    provide: {
      facebook: {
        fbq,
      },
    },
  }
})
