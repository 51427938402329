import { Sentry } from '~/utils/import-sentry'

export const useLogError = (error: any, throwNext = false, contexts?: any) => {
  // on SSR after error, we unfortunately getting nuxtApp unavailable exception so we simply rely on env mode here
  // const dsn = useRuntimeConfig().public.sentryDsn
  // const { $sentryCaptureException } = useNuxtApp()

  if (error.message?.includes(useSkipKey())) {
    console.warn('Skip error from capture', error)

    return
  }

  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.captureException(error, { contexts })
    } catch (error: any) {
      console.error('Failed to capture exception', error)
    }
  } else {
    console.log('The error will be captured on production', error)
  }

  if (throwNext) {
    throw error
  }
}
