declare let window: {
  dataLayer: any[]
}

const dataLayer = window.dataLayer || (window.dataLayer = [])

function loadScript(tag: string) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.async = true
    script.charset = 'utf-8'
    script.src = `https://www.googletagmanager.com/gtm.js?id=${tag}`

    try {
      head.appendChild(script)
    } catch (error: any) {
      console.log('failed to append gtag', error)
    }

    script.onload = () => resolve({ success: true })
    script.onerror = (error) => reject(error)
  })
}

function debug(message: string, args?: any) {
  console.log(`[gtm] ${message}`, args)
}

function gTag(name: string, data: any) {
  dataLayer.push([name, data])
}

function gTagPush(data: any) {
  dataLayer.push(data)
}

function gTagView(name: string, path: string, data = {}) {
  dataLayer.push({
    ...data,
    event: 'content-view',
    'content-name': path,
    'content-view-name': name,
  })
}

export async function createTag(tag: string, isDebug = false) {
  if (!isDebug) {
    gTagPush({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    await loadScript(tag)
    console.log('analytics: gtm successfully loaded')

    return {
      gTag,
      gTagPush,
      gTagView,
    }
  } else {
    return {
      gTag(...args: any) {
        debug('gTag:', args)
      },
      gTagPush(...args: any) {
        debug('gTagPush:', args)
      },
      gTagView(...args: any) {
        debug('gTagView:', args)
      },
    }
  }
}
