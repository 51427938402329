<template>
  <div>
    <NuxtLayout>
      <!-- TODO: https://app.asana.com/0/1202924432057564/1203829013202626 -->
      <!-- <NuxtLoadingIndicator color="#0D65D8" :height="2" /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import './patch-server-log'
import { useI18n } from 'vue-i18n'
import { setLocale } from '@vee-validate/i18n'
import { Preferences } from '@capacitor/preferences'
import { useAuth } from '~/stores/auth'
import { useUsers } from '~/stores/users'
// import { useChat } from '~/stores/chat'
import { useCurrentLang } from '~/stores/current-lang'

const { $dayjs } = useNuxtApp()
const { locale, t } = useI18n()
const { setLang } = useCurrentLang()
const authStore = useAuth()
const usersStore = useUsers()
// const chatStore = useChat()
const { isApp } = useDetect()
const CapacitorLangKey = 'lang'

// The CloudPayments widget disrupts the mobile behavior of the safe-area-inset-top CSS environment variable by adding a custom meta tag.
// After closing the widget, we need to reapply the correct viewport meta tag and trigger a reflow to restore the proper layout for devices with safe areas.
if (process.client && isApp) {
  function addViewportMetaTag() {
    const metaViewport = document.createElement('meta')

    metaViewport.name = 'viewport'
    metaViewport.content =
      'width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'

    document.head.appendChild(metaViewport)
  }

  function forceRecalculateSafeArea() {
    // Temporarily change a CSS property to trigger a reflow
    document.documentElement.style.paddingBottom = '1px'
    setTimeout(() => {
      document.documentElement.style.paddingBottom = ''
    })
  }
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (
          node instanceof Element &&
          node.tagName === 'META' &&
          node.getAttribute('name') === 'viewport'
        ) {
          document.head.removeChild(node)
          observer.disconnect()
          addViewportMetaTag()
          forceRecalculateSafeArea()
          observer.observe(document.head, { childList: true })
        }
      })
    })
  })
  observer.observe(document.head, { childList: true })
}

try {
  // we want to check and load profile here as sometimes we need failed to load it from middleware
  if (authStore.auth && !authStore.profile) {
    await authStore.fetchProfile()
  }

  authStore.setSentryUser()

  if (useDetect().isInIframe) {
    console.log('auth: request auth from parent frame')
    window.parent &&
      window.parent.postMessage({ message_type: 'auth-request' }, '*')
  }

  // for some reason sometimes (Safari iOS) thirdparty widgets breaks safe-area-inset-top env css variables
  // so we save them into custom properties

  await authStore.updateProfileTimezone()

  // TODO: uncomment on complete chat integration
  // watch(
  //   () => authStore.auth,
  //   () => {
  //     if (process.server) {
  //       return
  //     }
  //     if (authStore.auth) {
  //       chatStore.initChat()
  //     } else {
  //       chatStore.resetChat()
  //     }
  //   },
  //   { immediate: true }
  // )

  if (isApp) {
    try {
      const appLang = (await Preferences.get({ key: CapacitorLangKey })).value

      // if page open after push with a link on language that is not english (locale.value) than we keep that language
      // if page open after push with a link on english language (link without lang prefix) than we change language of the page to that user set in app
      if (appLang && appLang !== locale.value && locale.value === 'en') {
        useSetLanguage(appLang)
        onNuxtReady(() => {
          // ensure the language setting is applied after Nuxt is fully ready
          useSetLanguage(appLang)
        })
      }
    } catch (error: any) {
      useLogError(error)
    }
    document.body.classList.add('is-app')
  }

  onNuxtReady(() => {
    console.log('nuxt: ready', performance.now() / 1000)
    document.body.classList.add('nuxt-ready')
  })

  setLang(locale.value)
} catch (error: any) {
  showError(error)
}

watch(
  () => locale.value,
  async () => {
    try {
      setLang(locale.value)
      $dayjs.locale(locale.value)
      setLocale(locale.value)
      isApp && Preferences.set({ key: CapacitorLangKey, value: locale.value })
      await usersStore.reloadUser()
    } catch (error: any) {
      showError(error)
    }
  }
)

useHeadMeta({
  title: () => t('head_meta.default_title'),
  description: () => t('head_meta.default_description'),
  keywords: () => t('head_meta.default_keywords'),
  image: '/og-image.png',
})
</script>
