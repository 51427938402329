import { createTag } from './helpers'

export default defineNuxtPlugin(() => {
  const tag = useRuntimeConfig().public.gtmTag

  if (!tag || useDetect().isInIframe) {
    return
  }

  async function initTag() {
    try {
      await createTag(tag)
    } catch (error: any) {
      console.warn('error on create gtag script', error)
    }
  }

  initTag()
})
