<template>
  <div
    class="relative w-full h-full flex flex-col"
    :class="hasError && 'has-error'"
    style="scroll-margin-top: calc(4rem + var(--sat))"
  >
    <div class="mb-2 pl-4">
      <label v-if="label" class="block text-sm font-medium leading-5">
        {{ label }}
        {{ required ? '*' : '' }}
      </label>
      <div v-if="description" class="text-sm text-gray-700">
        {{ description }}
      </div>
    </div>
    <div class="h-full" :class="inputBlockClass">
      <input
        ref="input"
        class="hidden"
        v-bind="$attrs"
        type="file"
        :accept="accept.join(', ')"
        :multiple="multiple"
        tabindex="-1"
        aria-label=""
        @change="onChange"
      />

      <div
        ref="dropZone"
        class="relative group h-full flex justify-center overflow-hidden transition duration-150 ease-in-out"
        :class="[
          proxyBorder,
          proxyRounded,
          dragover && 'shadow-outline-blue border-blue-300',
          errorMessage ? 'border-red-400' : 'border-gray-300',
        ]"
      >
        <div
          class="relative h-full cursor-pointer flex-1 flex justify-center items-center"
          :class="(props.loading || props.disabled) && 'pointer-events-none'"
          @click="!cropper && onTarget()"
        >
          <slot name="preview">
            <img
              v-if="imageSrc"
              ref="imageElement"
              :class="[props.loading && 'animate-pulse', previewFit]"
              class="block w-full h-full object-contain"
              :src="imageSrc"
            />
            <div
              v-else
              class="space-y-1 text-center px-6 py-5"
              :class="props.loading && 'animate-pulse'"
            >
              <svg
                class="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="text-sm text-gray-600">
                <button
                  class="rounded-md font-medium text-primary-900 hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                  type="button"
                >
                  {{ $t('common.upload_a_file') }}
                </button>
                <span>{{ $t('common.or') }}</span>
                <span>{{ $t('common.drag_and_drop') }}</span>
              </p>
              <p class="text-xs text-gray-500">
                {{
                  accept
                    .map((item) => item.split('/')[1])
                    .join(', ')
                    .toUpperCase()
                }}
                <template v-if="maxSize">
                  up to
                  {{ maxSize }}MB
                </template>
              </p>
            </div>
          </slot>
        </div>
        <div
          v-if="showDeleteButton && imageSrc"
          class="absolute top-2 right-2 hidden group-hover:block"
        >
          <BaseButton
            class="w-10 !p-0"
            :disabled="props.disabled"
            @click="onDeleteClick()"
          >
            <BaseIcon name="outline_trash" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="flex pt-px text-sm leading-4">
      <transition
        enter-active-class="transition-all duration-300"
        enter-from-class="transform -translate-y-3 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition-all duration-300"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-3 opacity-0"
      >
        <span v-if="hasError" class="text-red-600 pl-4 mt-1">
          <slot name="error">
            {{ errorMessage }}
          </slot>
        </span>
      </transition>
    </div>
    <div
      v-if="useCropper"
      class="flex flex-col gap-x-4 gap-y-2 mt-4 justify-center"
    >
      <div v-if="!cropperRatio" class="flex gap-1 flex-wrap justify-center">
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!fileList.length"
          @click="setCropperRatio(16 / 9)"
        >
          16:9
        </BaseButton>
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!fileList.length"
          @click="setCropperRatio(4 / 3)"
        >
          4:3
        </BaseButton>
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!fileList.length"
          @click="setCropperRatio(1)"
        >
          1:1
        </BaseButton>
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!fileList.length"
          @click="setCropperRatio(2 / 3)"
        >
          2:3
        </BaseButton>
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!fileList.length"
          @click="setCropperRatio()"
        >
          Free
        </BaseButton>
        <div class="flex">
          <BaseButton
            :disabled="!fileList.length"
            size="none"
            rounded="none"
            class="rounded-l-xl w-6 h-8"
            title="zoom(-0.1)"
            @click="setCropperZoom(-0.1)"
          >
            -
          </BaseButton>
          <div class="w-[1px] bg-white" />
          <BaseButton
            :disabled="!fileList.length"
            size="none"
            rounded="none"
            class="rounded-r-xl w-6 h-8"
            title="zoom(+0.1)"
            @click="setCropperZoom(0.1)"
          >
            +
          </BaseButton>
        </div>
        <BaseButton
          size="none"
          class="h-8 px-2"
          :disabled="!cropper"
          title="reset"
          @click="resetCropper"
        >
          <BaseIcon name="outline_arrows_path" size="xs" />
        </BaseButton>
      </div>
      <div class="flex gap-1 flex-wrap justify-center">
        <BaseColorWidget
          v-model="cropperBg"
          :disabled="!fileList.length"
          allow-no-color
          title="background for svg"
          @update:model-value="onUpdateBgColor"
        />

        <BaseButton :disabled="!fileList.length" theme="success" @click="save">
          Save
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import type { RuleExpression } from 'vee-validate'
import { useSimpleInput } from './use-simple-input'
import { readFile, getImageExtension } from '~/utils/file-utils'

const roundedMap = {
  normal: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  full: 'rounded-full',
  none: '',
}

const borderMap = {
  dashed: 'border-2 border-dashed',
  solid: 'border-2',
  none: '',
}

const previewFitMap = {
  none: 'object-none',
  contain: 'object-contain',
  cover: 'object-cover',
}

const props = defineProps({
  modelValue: {
    type: [Object, String] as PropType<File | string>,
    default: undefined,
  },
  value: {
    type: [Object, String] as PropType<File | string>,
    default: undefined,
  },
  preview: {
    type: String,
    default: '',
  },
  previewFit: {
    type: String as PropType<keyof typeof previewFitMap>,
    default: 'contain',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  rules: {
    type: [String, Function, Object] as PropType<
      RuleExpression<boolean | object | string | number | any[] | null>
    >,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: Array as PropType<string[]>,
    default: () => ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  maxSize: {
    type: Number,
    default: undefined,
  },
  rounded: {
    type: String as PropType<keyof typeof roundedMap>,
    default: 'normal',
  },
  border: {
    type: String as PropType<keyof typeof borderMap>,
    default: 'dashed',
  },
  useCropper: {
    type: Boolean,
    default: false,
  },
  cropperRatio: {
    type: Number,
    default: undefined,
  },
  showDeleteButton: {
    type: Boolean,
    default: false,
  },
  inputBlockClass: {
    type: String,
    default: '',
  },
})

const emits = defineEmits([
  'update:modelValue',
  'drop',
  'change',
  'delete',
  'error:size',
  'error:type',
])
const dropZone = ref<InstanceType<typeof HTMLDivElement> | null>(null)
const input = ref<InstanceType<typeof HTMLInputElement> | null>(null)

const cropperPreview = ref<string | null>(null)
const imageElement = ref<HTMLImageElement | null>(null)
const cropper = ref<Cropper | null>(null)
const fileType = ref('image/jpeg')
const cropperBg = ref('#ffffff')
const isImageModified = ref(false)
const dragover = ref(false)
const fileList = ref<File[]>([])

const imageSrc = computed(() => {
  return cropperPreview.value || props.preview || ''
})

const previewFit = computed(() => previewFitMap[props.previewFit] || '')

onMounted(() => {
  addEventListener(
    document,
    'drag dragend dragover dragenter dragleave drop',
    onDragPrevent
  )
  addEventListener(dropZone.value, 'dragover dragenter', onDragOver)
  addEventListener(dropZone.value, 'dragleave dragend drop', onDragStop)
  addEventListener(dropZone.value, 'drop', onDrop)
})

onUnmounted(() => {
  removeEventListener(
    document,
    'drag dragend dragover dragenter dragleave drop',
    onDragPrevent
  )
  removeEventListener(dropZone.value, 'dragover dragenter', onDragOver)
  removeEventListener(dropZone.value, 'dragleave dragend drop', onDragStop)
  removeEventListener(dropZone.value, 'drop', onDrop)

  cropper.value?.destroy()
})

const { errorMessage: inputErrorMessage, handleChange } = useSimpleInput(
  props,
  getCurrentInstance()
)

watch(
  () => props.modelValue,
  (value) => {
    handleChange(value)
  }
)

const proxyRounded = computed(() => props.rounded && roundedMap[props.rounded])
const proxyBorder = computed(() => props.border && borderMap[props.border])
const errorMessage = computed(() => props.error || inputErrorMessage.value)

const slots = useSlots()
const hasError = computed(
  () => !props.disabled && (errorMessage.value || slots.error)
)

function addEventListener(
  el: HTMLElement | Document | null,
  eventsList: string,
  listener: (e: any) => void
) {
  const events = eventsList.split(' ')
  events.forEach((event) => {
    el?.addEventListener(event, listener)
  })
}

function removeEventListener(
  el: HTMLElement | Document | null,
  eventsList: string,
  listener: (e: any) => void
) {
  const events = eventsList.split(' ')
  events.forEach((event) => {
    el?.removeEventListener(event, listener)
  })
}

function clear() {
  if (input.value) {
    input.value.value = '' // [] ?
  }
  fileList.value = []
}

function onDragPrevent(e: DragEvent) {
  e.preventDefault()
  e.stopPropagation()
}

function onDragOver() {
  dragover.value = true
}

function onDragStop() {
  dragover.value = false
}

function checkSize(file: File | null) {
  // file size after cropper is much bigger as it stored in uncompressed way it looks like
  // so we check it right after open
  if (!file) {
    emits('error:type')
    return false
  }

  if (props.maxSize && file.size > props.maxSize * 1024 * 1024) {
    emits('error:size')
    return false
  }

  return true
}

async function setCropperPreview(file: File) {
  const fileData = await readFile(file)
  fileType.value = file.type

  if (fileData) {
    cropperPreview.value = fileData.toString()
  }
}

function onDrop(e: DragEvent) {
  e.preventDefault()

  if (e.dataTransfer?.items) {
    const files = Object.values(e.dataTransfer.items)
      .filter((file) => {
        return !props.accept || props.accept.includes(file.type)
      })
      .map((file) => file.getAsFile())
      .filter((item): item is File => item !== null)

    if (!checkSize(files[0])) {
      return
    }

    fileList.value = files

    if (props.useCropper) {
      setCropperPreview(files[0])
    } else {
      addFiles(files)
    }

    emits('drop', files)
  }
}

function onChange(event: Event) {
  const target = event.target as HTMLInputElement

  if (target.files) {
    const files = Object.values(target.files).filter((file) => {
      return !props.accept || props.accept.includes(file.type)
    })

    if (!checkSize(files[0])) {
      return
    }

    fileList.value = files

    if (props.useCropper) {
      setCropperPreview(files[0])
    } else {
      addFiles(files)
    }
  }
}

function onTarget() {
  input.value?.click()
}

function onDeleteClick(): void {
  if (cropper.value) {
    cropper.value.destroy()
  }
  cropperPreview.value = ''
  emits('delete')
}

async function setCropperRatio(ratio?: number) {
  if (!cropper.value) {
    await initCropper(fileList.value[0])
  }

  if (!cropper.value) {
    return
  }

  cropper.value.setAspectRatio(ratio || NaN)

  if (!ratio) {
    return
  }

  const containerData = cropper.value.getContainerData()
  const containerWidth = containerData.width
  const containerHeight = containerData.height
  const containerRatio = containerWidth / containerHeight

  if (containerRatio > ratio) {
    cropper.value?.setCropBoxData({
      height: containerHeight,
      left: (containerWidth - containerHeight * ratio) / 2,
    })
  } else if (containerRatio < ratio) {
    cropper.value?.setCropBoxData({
      width: containerWidth,
      top: (containerHeight - containerWidth / ratio) / 2,
    })
  }

  isImageModified.value = true
}

async function setCropperZoom(zoom?: number) {
  if (!cropper.value) {
    await initCropper(fileList.value[0])
  }

  cropper.value?.zoom(zoom || NaN)

  isImageModified.value = true
}

async function onUpdateBgColor() {
  if (!cropper.value) {
    await initCropper(fileList.value[0])

    isImageModified.value = true
  }
}

function resetCropper() {
  cropper.value?.reset()
  cropper.value?.setAspectRatio(NaN)

  isImageModified.value = false
}

async function initCropper(file: File | null) {
  if (file) {
    await setCropperPreview(file)

    await nextTick()

    if (props.useCropper && imageElement.value) {
      if (cropper.value) {
        cropper.value.destroy()
      }

      cropper.value = new Cropper(imageElement.value, {
        viewMode: 0,
        aspectRatio: props.cropperRatio,
        autoCropArea: 1,
        rotatable: false,
        minContainerHeight: 200,
        // otherwise we lose the center of the image. Delete if it's ok
        zoomOnWheel: false,
      })
    }
  }
}

function save() {
  if (!isImageModified.value) {
    addFiles(fileList.value)
  } else {
    if (!cropper.value) {
      return
    }

    const canvas = cropper.value?.getCroppedCanvas({
      fillColor: cropperBg.value || undefined,
    })

    cropperPreview.value = canvas.toDataURL('image/jpeg', 0.9)

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return
        }

        const file = new File(
          [blob],
          `cropped-image${getImageExtension(fileType.value)}`,
          {
            lastModified: Date.now(),
            type: fileType.value,
          }
        )

        addFiles([file])
      },
      fileType.value,
      0.9
    )
  }
  cropper.value?.destroy()
  cropper.value = null
}

function addFiles(files: Array<File | null>) {
  if (files && files.length) {
    emits('change', props.multiple ? files : files[0])
    emits('update:modelValue', props.multiple ? files : files[0])

    nextTick(() => {
      // TODO: allow validation via use-simple-input
      // if (validate) {
      //   handleBlur()
      //   handleInput(props.modelValue)
      //   validate()
      // }
      clear()
    })

    return files
  }
}

defineExpose({ onTarget })
</script>
