import { createTag, debug } from './helpers'

export default defineNuxtPlugin(() => {
  const id = useRuntimeConfig().public.linkedinTagId

  function initTag() {
    try {
      return createTag(id, !id)
    } catch (error: any) {
      console.warn('analytics: failed to init Linkedin Tag', error)
      return debug
    }
  }

  const lintrk = initTag()

  return {
    provide: {
      linkedin: {
        lintrk,
      },
    },
  }
})
