import { createPixel } from './helpers'

export default defineNuxtPlugin(() => {
  const id = useRuntimeConfig().public.vkPixelId

  if (!id || useDetect().isInIframe) {
    return
  }

  async function initPixel() {
    try {
      await createPixel(id)
    } catch (error: any) {
      console.warn('error on create VK Pixel script', error)
    }
  }

  initPixel()
})
