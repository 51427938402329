<template>
  <div ref="editorEl" class="description_editor" />
</template>

<script setup lang="ts">
import '@toast-ui/editor/dist/toastui-editor.css'
import type { Media } from '~/models/media'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])
const editorEl = ref<HTMLElement | null>(null)
let editor: any

const { pushNotification } = useNotifications()

async function addImageBlobHook(
  blob: Blob,
  callback: (url: string, alt: string) => void
) {
  try {
    const formData = new FormData()

    formData.append('category', 'image')
    formData.append('media', blob)

    const baseURL = useRuntimeConfig().public.gatewayAdminApi
    const { data } = await useAuthFetch<{ data: Media }>('/media', {
      baseURL,
      method: 'POST',
      body: formData,
    })

    callback(data.url, 'uploaded image')

    pushNotification({
      title: 'Image was successfully uploaded',
    })
  } catch (error: any) {
    pushNotification({
      title: 'Error on image upload',
      theme: 'destructive',
    })
    useLogError(error)
  }
}

onMounted(async () => {
  // @ts-ignore-next-line
  const { default: Editor } = await import('@toast-ui/editor')
  if (!editorEl.value) {
    return
  }
  editor = new Editor({
    el: editorEl.value,
    height: '300px',
    previewStyle: 'tab',
    initialEditType: 'wysiwyg',
    initialValue: props.modelValue || '',
    toolbarItems: [
      ['heading', 'bold', 'italic'],
      ['hr', 'quote'],
      ['ul', 'ol', 'indent', 'outdent'],
      ['link'],
      ['image'],
    ],
    hooks: {
      addImageBlobHook,
    },
    autofocus: false,
    events: {
      change: () => {
        const content = editor.getMarkdown()

        // if we delete the text, but leave the formatting (for example, the H1 header)
        // then we save text containing only the hashtags '# '
        const cleanedContent = content.replace(/#/g, '').trim()

        emit('update:modelValue', cleanedContent.length ? content : '')
      },
    },
  })
})

// without this watcher if we click on the block that was edited by someone else we see the old data
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue !== editor.getMarkdown()) {
      editor.setMarkdown(props.modelValue)
    }
  }
)
</script>

<style scope lang="scss">
.description_editor {
  width: 100%;

  p,
  li {
    font-size: 1rem !important;
  }

  h1,
  h2 {
    border-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal !important;
    padding: 1.25rem 0 0.75rem !important;
    margin: 0 !important;
  }

  h1 {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  h4 {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  h5 {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  h6 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}
</style>
