<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      color: formData.textColor,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
      '--text-color': formData.textColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold"
      :style="{ textAlign: formData.titleAlign, color: formData.titleColor }"
      :class="
        (!formData.image || formData.imagePosition === 'right') &&
        'mb-4 md:mb-6'
      "
    >
      {{ formData.title }}
    </h2>
    <div
      class="flex gap-8"
      :class="formData.imagePosition === 'right' && 'flex-row-reverse'"
    >
      <div v-if="formData.image" class="w-1/3 mt-4 hidden md:block">
        <div>
          <img
            :src="formData.image"
            class="w-full rounded-xl overflow-hidden"
          />
        </div>
      </div>
      <div class="flex-1 flex" :class="!formData.image && formData.align">
        <div
          class="flex-1 w-full flex flex-col gap-2"
          :style="{
            maxWidth:
              !formData.image &&
              formData.align !== 'w-full' &&
              formData.maxWidth
                ? `${formData.maxWidth}px`
                : '',
          }"
        >
          <div
            v-for="(tab, index) in formData.tabs"
            :key="tab.label + index"
            class="bg-gray-80 rounded-xl"
            :style="{
              'background-color': formData.itemsBgColor,
            }"
          >
            <div
              class="pt-4 pb-4 px-6 text-lg font-medium flex items-center justify-between cursor-pointer gap-2 hover:opacity-80 transition duration-150"
              :style="{
                color: formData.itemsTitlesColor,
              }"
              @click="toggleTab(index)"
            >
              <div>
                <span v-if="formData.numeration">
                  {{ (index + 1).toString().padStart(2, '0') }}.
                </span>
                {{ tab.label }}
              </div>
              <div>
                <BaseIcon
                  name="outline_chevron_down"
                  class="transition-all duration-300"
                  :class="isOpen(index) && 'transform rotate-180'"
                />
              </div>
            </div>
            <div
              :ref="(el) => setSlotRef(el, index)"
              :style="{
                height: isOpen(index)
                  ? slotRefs[index]?.scrollHeight + 'px'
                  : '0',
              }"
              class="transition-all duration-300 overflow-hidden"
              :class="isOpen(index) && '-translate-y-4'"
            >
              <div
                class="tab-text marked text-sm pt-2 px-6"
                v-html="
                  getMarkedTextWithBlankLinks(tab.text, formData.linkColor)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const slotRefs = ref<Element[]>([])

function setSlotRef(el: any, index: number) {
  if (el && el instanceof HTMLElement) {
    slotRefs.value[index] = el
  }
}

const { getMarkedTextWithBlankLinks } = useMarkedText()

const openTabs = ref<number[]>([])

const toggleTab = (index: number) => {
  if (openTabs.value.includes(index)) {
    openTabs.value = openTabs.value.filter((i) => i !== index)
  } else {
    props.formData.closeOther
      ? (openTabs.value = [index])
      : openTabs.value.push(index)
  }
}

const isOpen = (index: number) => {
  return openTabs.value.includes(index)
}
</script>

<style lang="scss">
.tab-text.marked {
  h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
